import moment from 'moment';
import { calculateAge } from '../../../../helpers/datetime';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import { RxPrescriptionProperties } from '../../../../types/rxPrescription.type';
import Separator from '../../../Separator';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';
import { Button } from '../../../Button/Button/Button';
import { useHistory } from 'react-router-dom';
import { Ellips } from '../../../../screens/app/BalanceScreen';
import { Typography } from 'antd';

interface Props {
	prescription: RxPrescriptionProperties;
	nextStep: () => void;
}

export default function DetailRxHistory(props: Props) {
	const history = useHistory();

	function createRx() {
		history.push(
			`/app/rx-and-lab-request/create-rx?docTmpId=${props.prescription?.rxPrescriptionId}`,
		);
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">Rx Information</p>
					</div>
					<div className="flex-1 flex gap-x-3">
						<div className="flex-1">
							<p className="m-0 text-3.5 text-gray-60">Prescribed on</p>

							<div className="flex items-center gap-x-1 mt-3">
								<p className="m-0 text-4">
									{moment(props.prescription?.createdAt).format(
										'MMMM DD, YYYY',
									)}
								</p>
								<Ellips />
								<Typography.Text style={{ fontSize: 14 }}>
									{props?.prescription?.createdAt &&
										moment(props?.prescription?.createdAt).format('H:mm:ss')}
								</Typography.Text>
							</div>
						</div>
						<div className="flex-1">
							<p className="m-0 text-3.5 text-gray-60">Valid Until</p>
							<p className="m-0 mt-2">
								{moment(props.prescription.validUntil).format('MMMM DD, YYYY')}
							</p>
						</div>
					</div>
				</div>
				<div className="py-5">
					<Separator />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">Patient Information</p>
					</div>
					<div className="flex-1">
						<div>
							<p className="m-0 text-3.5 text-gray-60">Patient ID</p>
							<p className="m-0 mt-2">{props.prescription.patientId || '-'}</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Patient Name</p>
							<p className="m-0 mt-2">
								{getName(props.prescription.metaPatient)}
							</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Age</p>
							<p className="m-0 mt-2">
								{calculateAge(getBirthdate(props.prescription.metaPatient))}{' '}
								yrs. old
							</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Gender</p>
							<p className="m-0 mt-2">
								{getGender(props.prescription.metaPatient)}
							</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Phone</p>
							<p className="m-0 mt-2">
								{props.prescription.metaPatient?.phone || '-'}
							</p>
						</div>
					</div>
				</div>
				<div className="py-5">
					<Separator />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">Medicines</p>
					</div>
					<div className="flex-1">
						{props.prescription.prescriptionItems?.map((item, index) => (
							<div key={item.drugMedicineDetail.drugMedicineId}>
								<div className={index === 0 ? 'pb-3' : 'py-3'}>
									<Item
										name={item.drugMedicineDetail.drugMedicineName}
										dosage={item.drugMedicineDetail.dose}
										quantity={item.quantity}
										sig={item.sig}
										hasPoint={
											!!item.drugMedicineDetail.points &&
											!!item.drugMedicineDetail.partner
										}
									/>
								</div>
								<Separator />
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="py-3 px-4">
				<div className="flex gap-x-2.5 max-w-sm ml-auto">
					<Button type="TRANSPARENT" onClick={createRx}>
						Use this RX
					</Button>
					<Button type="SOLID" onClick={props.nextStep}>
						Preview
					</Button>
				</div>
			</div>
		</div>
	);
}

function Item(props: {
	name?: string;
	hasPoint: boolean;
	dosage?: string;
	quantity: number;
	sig: string;
}) {
	return (
		<div>
			<div className="flex gap-x-1 items-center">
				<p className="m-0">{props.name}</p>
				{props.hasPoint && <Logo width={18} height={18} />}
			</div>
			<div className="mt-2 flex gap-x-2.5">
				<p className="m-0 flex-1 text-3.5 text-black-70">{props.dosage}</p>
				<p className="m-0 flex-1 text-3.5 text-black-70">
					qty. {props.quantity}
				</p>
			</div>
			<div className="mt-2">
				<p className="m-0 text-3.5 text-black-70">{props.sig}</p>
			</div>
		</div>
	);
}
