import { useEffect, useState } from 'react';
import { CheckSquare, Plus } from 'react-feather';
import {
	LabRequestItem,
	LabRequestProperties,
} from '../../../../types/labRequest.type';
import EmptyList from '../../../EmptyList';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import { ReactComponent as LabRequest } from '../../../../assets/icons/doc-multi-choice-with-pen.svg';
import { theme } from '../../../../assets/theme';
import ModalAddProcedure from './Components/Modal/ModalAddProcedure';
import { IndividualHealthServiceProperties } from '../../../../types/individualHealthService.type';
import IconButton from '../../../Button/IconButton';
import { Button } from '../../../Button/Button/Button';
import {
	apiCreateLabRequest,
	apiGetLabRequest,
	apiUpdateLabRequest,
} from '../../../../api/labRequest';
import { useAuth } from '../../../../context/auth.context';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { calculateAge } from '../../../../helpers/datetime';
import { getName } from '../../../../helpers/name';
import ModalInfoPatient from '../CreateRx/Components/Modal/ModalInfoPatient';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import COLORS from '../../../../assets/globalColors';
import ModalConfirmationChangePhone from '../CreateRx/Components/Modal/ModalConfirmationChangePhone';
import { validatePhoneNumber } from '../../../../helpers/validation';

const forms = [
	{
		label: 'Phone Number',
		name: 'phone',
		prefix: (
			<p
				className="m-0 text-black whitespace-nowrap"
				style={{ borderRight: '1px solid gray', paddingRight: 10 }}
			>
				+63
			</p>
		),
		type: EFormType.SINGLE_LINE,
		isRequired: true,
		rows: undefined,
	},
	{
		label: 'Patient Name',
		name: 'patientName',
		type: EFormType.SINGLE_LINE,
		isRequired: true,
		rows: undefined,
	},
	{
		label: 'Gender',
		name: 'gender',
		type: EFormType.ITEM_PICKER_H,
		items: ['MALE', 'FEMALE'],
		isRequired: true,
	},
	{
		label: 'Age',
		name: 'age',
		suffix: <p className="m-0 text-black-60 whitespace-nowrap">Years old</p>,
		formType: 'number',
		min: 0,
		type: EFormType.SINGLE_LINE,
		isRequired: true,
	},
	{
		label: 'Valid Until',
		name: 'validUntil',
		type: EFormType.DATE_PICKER,
		isRequired: true,
	},
];

interface Props {
	labRequest?: LabRequestProperties;
	step: number;
	setLabRequest: React.Dispatch<
		React.SetStateAction<LabRequestProperties | undefined>
	>;
	searchPhone?: string | undefined;
	patientProfile: any;
	setPatientProfile: React.Dispatch<React.SetStateAction<any>>;
	setNextStep: () => void;
	setIsEditing: () => void;
	setSearchPhone: React.Dispatch<React.SetStateAction<any>>;
	isPhoneDisable?: boolean;
	setIsPhoneDisable?: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateLabRequest(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [showModalAddProcedure, setShowModalAddProcedure] = useState(false);
	const [showModalChangePhone, setShowModalChangePhone] = useState(false);

	// const [patientProfile, setPatientProfile] = useState<{
	//   [key: string]: any;
	// }>();
	// const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
	//   useState(false);
	// const [foundPatient, setFoundPatient] = useState<any>();
	// const [searchPhone] = useDebounce(patientProfile?.phone, 500);
	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	useEffect(() => {
		props.setLabRequest((old) => ({
			...old,
			validUntil: todayAfter3Month.toString(),
		}));
	}, []);

	useEffect(() => {
		if (!window.location.search) return;

		const parse = window.location.search.replace('?', '').split('&');

		if (parse.length === 0) return;

		for (const q of parse) {
			const query = q.split('=');
			if (query[0] === 'docId' && !!query[1]) {
				(async () => {
					const res = await apiGetLabRequest(query[1]);
					if (res) {
						props.setLabRequest(res);
						const metaPatient = res?.metaPatient;
						const firstName = metaPatient?.firstName;
						const middleName = metaPatient?.middleName;
						const lastName = metaPatient?.lastName;
						const patientName = `${firstName ? firstName : ''}${
							middleName ? ` ${middleName}` : ''
						}${lastName ? ` ${lastName}` : ''}`;
						const age = metaPatient?.birthdate
							? moment().diff(metaPatient.birthdate, 'years', false)
							: undefined;
						const phone = metaPatient?.phone;
						props.setSearchPhone(phone);

						props.setPatientProfile({
							patientName,
							age,
							gender: metaPatient?.gender,
							phone,
						});
					}
				})();
			}
		}

		props.setIsEditing();
	}, [window.location.search]);

	useEffect(() => {
		if (props.patientProfile && !props.labRequest?.metaPatient) return;

		const metaPatient = props.labRequest?.metaPatient;
		const firstName = metaPatient?.firstName;
		const middleName = metaPatient?.middleName;
		const lastName = metaPatient?.lastName;
		const patientName = `${firstName ? firstName : ''}${
			middleName ? ` ${middleName}` : ''
		}${lastName ? ` ${lastName}` : ''}`;
		const age = metaPatient?.birthdate
			? moment().diff(metaPatient.birthdate, 'years', false)
			: undefined;
		const phone = props.searchPhone;

		props.setPatientProfile({
			patientName,
			age,
			gender: metaPatient?.gender,
			phone,
		});
	}, [props.labRequest?.metaPatient]);

	function appendProcedures(procedures: IndividualHealthServiceProperties[]) {
		const items: LabRequestItem[] = procedures.map((procedure) => ({
			healthServiceId: procedure.healthServiceId,
			metaHealthService: procedure,
		}));

		props.setLabRequest((old) => {
			const data = { ...old };
			if (data.procedures) {
				for (const procedure of items) {
					const index = data.procedures.findIndex(
						(oldProcedure) =>
							oldProcedure.healthServiceId === procedure.healthServiceId,
					);
					if (index >= 0) {
						data.procedures[index] = procedure;
					} else {
						data.procedures.push(procedure);
					}
				}
			} else {
				data.procedures = items;
			}
			return data;
		});
	}

	function removeProcedure(procedureId: string) {
		props.setLabRequest((old) => {
			const data = { ...old };
			data.procedures = old?.procedures?.filter(
				(procedure) => procedure.healthServiceId !== procedureId,
			);
			return data;
		});
	}

	async function saveLabRequest() {
		setIsLoading(true);

		let isSuccess = false;

		let data: LabRequestProperties = {
			...props.labRequest,
			patientId: props.patientProfile.patientId,
			isTemporary: true,
		};

		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (props.patientProfile?.age || 0),
		);
		const patientName = (props.patientProfile?.patientName as string)?.split(
			' ',
		);

		if (props.labRequest?.labRequestId) {
			const res = await apiUpdateLabRequest(props.labRequest.labRequestId, {
				...data,
				metaPatient: {
					firstName: patientName?.[0],
					middleName: patientName?.slice(1, patientName.length - 1)?.join(' '),
					lastName: patientName.length > 1 ? patientName?.at(-1) : undefined,
					birthdate: birthdate.toISOString(),
					gender: props.patientProfile?.gender,
					phone: props.searchPhone,
				},
			});
			if (res) {
				props.setLabRequest(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				doctorId: user?.userId,
				metaPatient: {
					firstName: patientName?.[0],
					middleName: patientName?.slice(1, patientName.length - 1)?.join(' '),
					lastName: patientName.length > 1 ? patientName?.at(-1) : undefined,
					birthdate: birthdate.toISOString(),
					gender: props.patientProfile?.gender,
					phone: props.searchPhone,
				},
			};

			const res = await apiCreateLabRequest(data);
			if (res) {
				props.setLabRequest(res);
				isSuccess = true;
			}
		}
		if (isSuccess) {
			props.setNextStep();
		} else {
			setIsLoading(false);
		}
	}

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Patient Information</p>
							</div>
							<div className="flex-1">
								{forms.map((form) => (
									<FormInput
										key={form.label}
										label={form.label}
										name={form.name}
										type={form.type}
										items={form?.items}
										required={form.isRequired}
										withoutHorizontalPadding={!!form.items}
										withoutVerticalPadding={!!form.items}
										value={
											form.name === 'validUntil'
												? props.labRequest?.validUntil
												: props.patientProfile?.[form.name]
										}
										suffix={form.suffix}
										prefix={form.prefix}
										formType={form.formType}
										min={form.min}
										rows={form.rows}
										onChange={(val) => {
											form.name === 'validUntil'
												? props.setLabRequest((old) => ({
														...old,
														validUntil: val,
												  }))
												: props.setPatientProfile((old: any) => ({
														...old,
														[form.name]: val,
												  }));
											form.name === 'phone' && props.setSearchPhone(val);
										}}
										disabledDate={(d: any) => !d || d.isBefore(new Date())}
										buttonRightLabel={
											form.name === 'phone' &&
											props.isPhoneDisable && (
												<p
													onClick={() => setShowModalChangePhone(true)}
													className="m-0 text-4 cursor-pointer text-primary"
												>
													Change Phone
												</p>
											)
										}
										disabled={form.name === 'phone' && props.isPhoneDisable}
									/>
								))}
							</div>
						</div>
						<div className="py-4">
							<Separator />
						</div>
						<div className="flex gap-x-3">
							<div className="w-1/4 pt-2">
								<p className="m-0 font-semibold text-4">Lab Request</p>
							</div>
							<div className="flex-1">
								<FormInput
									required
									label="Assessment/Diagnosis"
									placeholder="Write here"
									value={props.labRequest?.assessmentDiagnosis}
									onChange={(val) => {
										if (val.split('\n').length <= 6) {
											props.setLabRequest((old) => ({
												...old,
												assessmentDiagnosis: val,
											}));
										}
									}}
									type={EFormType.MULTI_LINE}
									rows={4}
								/>
								<div className="py-2">
									<div className="flex justify-between">
										<p className="m-0 text-4">
											Diagnostic Procedure(s){' '}
											<span style={{ color: COLORS.red }}>*</span>
										</p>
										<div>
											<button
												onClick={() => setShowModalAddProcedure(true)}
												type="button"
												className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
											>
												<Plus width={24} height={24} />
												Add Lab Request
											</button>
										</div>
									</div>
									<div>
										{props.labRequest?.procedures &&
										props.labRequest?.procedures.length > 0 ? (
											<div className="mt-2">
												{props.labRequest.procedures.map((procedure) => (
													<div
														key={procedure.healthServiceId}
														onClick={() =>
															removeProcedure(procedure.healthServiceId!)
														}
														style={{ cursor: 'pointer' }}
														className="py-2 flex gap-x-3"
													>
														<IconButton onClick={() => {}}>
															<CheckSquare
																width={20}
																height={20}
																color={theme.colors.primary}
															/>
														</IconButton>
														<p className="m-0">
															{procedure.metaHealthService.healthServiceName}
														</p>
													</div>
												))}
											</div>
										) : (
											<EmptyList
												icon={
													<LabRequest
														width={48}
														height={48}
														color={theme.colors.black50}
													/>
												}
												title="Lab Request Empty"
												description="Lab requests that you add will appear here"
											/>
										)}
									</div>
								</div>
								<FormInput
									label="Special Instructions"
									placeholder="Write here"
									type={EFormType.MULTI_LINE}
									rows={2}
									value={props.labRequest?.specialInstructions}
									onChange={(val) => {
										if (val.split('\n').length < 5) {
											props.setLabRequest((old) => ({
												...old,
												specialInstructions: val,
											}));
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<Separator />
				<div className="py-3 px-4">
					<div className="w-44 ml-auto">
						<Button
							type="SOLID"
							onClick={saveLabRequest}
							isLoading={isLoading}
							isDisable={
								!props.patientProfile?.patientName ||
								!props.patientProfile?.age ||
								!props.patientProfile?.gender ||
								!props.labRequest?.validUntil ||
								!props.labRequest?.assessmentDiagnosis ||
								!props.labRequest?.procedures ||
								props.labRequest.procedures.length === 0 ||
								!props.patientProfile?.phone ||
								validatePhoneNumber(props.patientProfile?.phone).length > 0
							}
						>
							Next
						</Button>
					</div>
				</div>
			</form>
			<ModalAddProcedure
				isShow={showModalAddProcedure}
				selectedHealthServices={props.labRequest?.procedures}
				onCloseModal={() => setShowModalAddProcedure(false)}
				addProcedures={appendProcedures}
				setLabRequest={props.setLabRequest}
				labRequest={props.labRequest}
			/>
			<ModalConfirmationChangePhone
				isVisible={showModalChangePhone}
				setVisibility={setShowModalChangePhone}
				actionHandler={() => {
					props.setPatientProfile({
						phone: undefined,
						patientId: undefined,
						patientName: undefined,
						age: undefined,
						gender: undefined,
					});
					props.setSearchPhone(undefined);
					props.setIsPhoneDisable?.(false);
					setShowModalChangePhone(false);
				}}
			/>
			{/* <ModalInfoPatient
        isVisible={isModalInfoPatientVisible}
        setVisibility={setModalInfoPatientVisible}
        data={foundPatient}
        actionHandler={() => {
          props.setPatientProfile({ ...props.patientProfile, ...foundPatient });
          setModalInfoPatientVisible(false);
        }}
      /> */}
		</>
	);
}