import { ReactComponent as CreateRx } from '../../../assets/icons/create-rx.svg';
import { ReactComponent as RxTemplate } from '../../../assets/icons/rx-template.svg';
import { ReactComponent as RxHistory } from '../../../assets/icons/rx-history.svg';
import { ReactComponent as CreateLabRequest } from '../../../assets/icons/create-lab-request.svg';
import { ReactComponent as LabRequestTemplate } from '../../../assets/icons/lab-request-template.svg';
import { ReactComponent as LabRequestHistory } from '../../../assets/icons/lab-request-history.svg';
import { ReactComponent as Formulary } from '../../../assets/icons/formulary.svg';
import { ChevronRight } from 'react-feather';
import Separator from '../../Separator';
import { EKycStatus } from '../../../types/doctor.type';
import { useAuth } from '../../../context/auth.context';

export enum RxAndLabRequestNav {
	Main = 'Main',
	CreateRx = 'Create-Rx',
	RxTemplate = 'Rx-Template',
	RxHistory = 'Rx-History',
	CreateLabRequest = 'Create-Lab-Request',
	LabRequestTemplate = 'Lab-Request-Template',
	LabRequestHistory = 'Lab-Request-History',
	Formulary = 'Formulary',
}

const sections = [
	{
		section: 'Prescription',
		menus: [
			{
				icon: <CreateRx />,
				title: 'Create Rx',
				description: 'Create Prescription',
				nav: RxAndLabRequestNav.CreateRx,
			},
			{
				icon: <RxTemplate />,
				title: 'Rx Template',
				description: 'Create and manage prescription template',
				nav: RxAndLabRequestNav.RxTemplate,
			},
			{
				icon: <RxHistory />,
				title: 'Rx History',
				description: 'See your Rx prescription history',
				nav: RxAndLabRequestNav.RxHistory,
			},
		],
	},
	{
		section: 'Lab Request',
		menus: [
			{
				icon: <CreateLabRequest />,
				title: 'Create Lab Request',
				description: 'Create lab request',
				nav: RxAndLabRequestNav.CreateLabRequest,
			},
			{
				icon: <LabRequestTemplate />,
				title: 'Lab Request Template',
				description: 'Create and manage lab request template',
				nav: RxAndLabRequestNav.LabRequestTemplate,
			},
			{
				icon: <LabRequestHistory />,
				title: 'Lab Request History',
				description: 'See your lab request history',
				nav: RxAndLabRequestNav.LabRequestHistory,
			},
		],
	},
	{
		section: 'Formulary',
		menus: [
			{
				icon: <Formulary />,
				title: 'Formulary',
				description: 'See drug details here',
				nav: RxAndLabRequestNav.Formulary,
			},
		],
	},
];

interface Props {
	addNav: (nav: RxAndLabRequestNav) => void;
}

export default function MainRxAndLabRequest(props: Props) {
	const { doctorKYCStatus } = useAuth();
	const isDisabled = doctorKYCStatus !== EKycStatus.APPROVED;

	function Item(cprops: {
		icon: JSX.Element;
		title: string;
		description: string;
		nav: RxAndLabRequestNav;
		isDisabled?: boolean;
	}) {
		return (
			<button
				disabled={cprops.isDisabled}
				onClick={() => props.addNav(cprops.nav)}
				className={
					(isDisabled ? 'bg-gray' : 'bg-transparent') +
					' w-full text-left flex gap-x-2.5 items-center py-4 px-2 border border-solid rounded-6 border-black-10 cursor-pointer'
				}
			>
				{cprops.icon}
				<div className="flex-1">
					<p className="m-0 font-semibold text-4">{cprops.title}</p>
					<p className="m-0 text-3.5 text-black-60">{cprops.description}</p>
				</div>
				<div className="flex">
					<ChevronRight />
				</div>
			</button>
		);
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				<h1 className="font-semibold text-6 m-0 w-full">
					Prescription & Lab Request
				</h1>
			</div>
			<Separator style={{ height: 2 }} />
			<div className="py-6 px-3 space-y-3">
				{sections.map((section, index) => (
					<div key={section.section} className="space-y-3">
						<p className="m-0 text-4">{section.section}</p>
						{section.menus?.map((menu) => (
							<Item
								key={menu.title}
								icon={menu.icon}
								title={menu.title}
								description={menu.description}
								nav={menu.nav}
								isDisabled={isDisabled}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	);
}
