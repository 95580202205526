import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import MainCreateLabRequest from '../../components/Webapp/RxAndLabRequest/CreateLabRequest/MainCreateLabRequest';
import MainCreateRx from '../../components/Webapp/RxAndLabRequest/CreateRx/MainCreateRx';
import MainFormulary from '../../components/Webapp/RxAndLabRequest/Formulary/MainFormulary';
import MainLabRequestHistory from '../../components/Webapp/RxAndLabRequest/LabRequestHistory/MainLabRequestHistory';
import MainLabRequestTemplate from '../../components/Webapp/RxAndLabRequest/LabRequestTemplate/MainLabRequestTemplate';
import MainRxAndLabRequest, {
	RxAndLabRequestNav,
} from '../../components/Webapp/RxAndLabRequest/MainRxAndLabRequest';
import MainRxHistory from '../../components/Webapp/RxAndLabRequest/RxHistory/MainRxHistory';
import MainRxTemplate from '../../components/Webapp/RxAndLabRequest/RxTemplate/MainRxTemplate';
import AppLayout from '../../layout/AppLayout';

const activePath = '/app/rx-and-lab-request';

interface Params {
	docType?: string;
	docId?: string;
}

export default function RxLabRequestScreen() {
	const history = useHistory();
	const { docType, docId } = useParams<Params>();
	const [nav, setNav] = useState(RxAndLabRequestNav.Main);

	useEffect(() => {
		if (!docType) {
			setNav(RxAndLabRequestNav.Main);
			return;
		}

		const nav = docType
			.split('-')
			.map((d) => d[0].toUpperCase() + d.slice(1))
			.join('-') as RxAndLabRequestNav;
		setNav(nav);
	}, [docType]);

	function addNav(nav: RxAndLabRequestNav) {
		history.push(`${activePath}/${nav.toLowerCase()}`);
	}

	return (
		<AppLayout activePath={activePath}>
			<Card className="flex flex-col">
				{nav === RxAndLabRequestNav.Main ? (
					<MainRxAndLabRequest addNav={addNav} />
				) : nav === RxAndLabRequestNav.CreateRx ? (
					<MainCreateRx />
				) : nav === RxAndLabRequestNav.RxTemplate ? (
					<MainRxTemplate />
				) : nav === RxAndLabRequestNav.RxHistory ? (
					<MainRxHistory />
				) : nav === RxAndLabRequestNav.CreateLabRequest ? (
					<MainCreateLabRequest />
				) : nav === RxAndLabRequestNav.LabRequestTemplate ? (
					<MainLabRequestTemplate />
				) : nav === RxAndLabRequestNav.LabRequestHistory ? (
					<MainLabRequestHistory />
				) : nav === RxAndLabRequestNav.Formulary ? (
					<MainFormulary />
				) : undefined}
			</Card>
		</AppLayout>
	);
}
