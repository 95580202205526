import { message } from 'antd';
import { isNumber } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CheckCircle, Circle } from 'react-feather';
import {
	apiCreateDoctorDocument,
	apiGetDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../api/doctorDocument';
import { theme } from '../../../../assets/theme';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
	MetaFitToWork,
	MetaMedicalCertificate,
	MetaOthers,
} from '../../../../types/doctorDocument.type';
import { IUser } from '../../../../types/user.type';
import { Button } from '../../../Button/Button/Button';
import IconButton from '../../../Button/IconButton';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import ModalInfoPatient from '../../RxAndLabRequest/CreateRx/Components/Modal/ModalInfoPatient';
import { useDebounce } from 'use-debounce';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import { calculateAge } from '../../../../helpers/datetime';
import { useAuth } from '../../../../context/auth.context';
import COLORS from '../../../../assets/globalColors';
import ModalConfirmationChangePhone from '../../RxAndLabRequest/CreateRx/Components/Modal/ModalConfirmationChangePhone';

const forms = [
	{
		formTitle: 'Patient Information',
		type: 'patientProfile',
		forms: [
			{
				label: 'Phone Number',
				name: 'phone',
				maxLength: 15,
				maxLine: 1,
				prefix: (
					<p
						className="m-0 text-black whitespace-nowrap"
						style={{ borderRight: '1px solid gray', paddingRight: 10 }}
					>
						+63
					</p>
				),
				type: EFormType.SINGLE_LINE,
				isRequired: true,
				rows: undefined,
			},
			{
				label: 'Patient Name',
				name: 'patientName',
				type: EFormType.SINGLE_LINE,
				isRequired: true,
				rows: undefined,
			},
			{
				label: 'Gender',
				name: 'gender',
				type: EFormType.ITEM_PICKER_H,
				items: ['MALE', 'FEMALE'],
				isRequired: true,
			},
			{
				label: 'Age',
				name: 'age',
				maxValue: 500,
				suffix: (
					<p className="m-0 text-black-60 whitespace-nowrap">Years old</p>
				),
				formType: 'number',
				min: 0,
				type: EFormType.SINGLE_LINE,
				isRequired: true,
			},
			{
				label: 'Address',
				name: 'address',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.MEDICAL_CERTIFICATE],
			},
		],
	},
	{
		formTitle: 'Detail Document',
		type: 'DoctorDocument',
		forms: [
			{
				label: 'Valid Until',
				name: 'validUntil',
				type: EFormType.DATE_PICKER,
				prefix: '',
				isRequired: true,
			},
			{
				label: 'Clinic / Hospital',
				name: 'clinicName',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.MEDICAL_CERTIFICATE],
			},
			{
				label: 'Diagnosis',
				name: 'diagnosis',
				maxLength: 200,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.MEDICAL_CERTIFICATE],
			},
			{
				label: 'Medical attention /rest for',
				name: 'medicalAttentionInDays',
				suffix: <p className="m-0 text-black-60">days</p>,
				type: EFormType.SINGLE_LINE,
				formType: 'number',
				maxLength: 2,
				maxValue: 1000,
				min: 0,
				showWhenType: [EMetaType.MEDICAL_CERTIFICATE],
			},
			{
				label: 'Clearance title',
				name: 'title',
				maxLength: 50,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Comorbid Conditions',
				name: 'comorbidConditions',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Procedure',
				name: 'procedures',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Symptoms',
				name: 'symptoms',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Functional Capacity',
				name: 'functionalCapacity',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Chest Xray',
				name: 'chestXRay',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: '12-L ECG',
				name: 'lecg12',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Blood tests',
				name: 'bloodTest',
				maxLength: 50,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Other tests',
				name: 'otherTest',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Patient Stratified',
				name: 'patientStratified',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Recommendations',
				name: 'recommendation',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.CLEARANCE],
			},
			{
				label: 'Comorbid Conditions',
				name: 'comorbidConditions',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Chief Complaint',
				name: 'chiefComplaint',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'PE Findings',
				name: 'peFindings',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Test Results',
				name: 'testResult',
				maxLength: 100,
				maxLine: 6,
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Physical Condition',
				name: 'physicalCondition',
				type: EFormType.ITEM_PICKER_H,
				showWhenType: [EMetaType.FIT_TO_WORK],
				items: ['FIT', 'UNFIT'],
			},
			{
				label: 'Recommendation',
				name: 'recommendation',
				type: EFormType.MULTI_LINE,
				maxLength: 100,
				maxLine: 6,
				showWhenType: [EMetaType.FIT_TO_WORK],
			},
			{
				label: 'Document Title',
				name: 'title',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.OTHERS],
				isRequired: true,
			},
			{
				label: 'Custom Content',
				name: 'content',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.OTHERS],
				rows: 12,
				isRequired: true,
			},
		],
	},
];

interface Props {
	type?: EMetaType;
	doctorDocumentId?: string;
	step: number;
	setDoctorDocumentId: React.Dispatch<React.SetStateAction<string | undefined>>;
	setNextStep: (step: number) => void;
}

export default function FormCreateDoctorDocument(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [doctorDocument, setDoctorDocument] = useState<{
		[key: string]: any;
	}>();
	const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
		useState(false);
	const [foundPatient, setFoundPatient] = useState<any>();
	const [foundRelatives, setFoundRelatives] = useState<any[]>([]);
	// const [searchPhone] = useDebounce(patientProfile?.phone, 500);
	const [searchPhone, setSearchPhone] = useState<string | undefined>('');
	const [isPhoneDisable, setIsPhoneDisable] = useState(false);
	const [isFree, setIsFree] = useState(false);
	const [showModalChangePhone, setShowModalChangePhone] = useState(false);

	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	useEffect(() => {
		setDoctorDocument((old) => ({
			...old,
			validUntil: todayAfter3Month.toString(),
		}));
	}, []);

	useEffect(() => {
		(async () => {
			if (!props.doctorDocumentId) return;

			setIsLoading(true);

			const res = await apiGetDoctorDocument(props.doctorDocumentId);
			if (res) {
				const metaPatient = res.metaPatient as IUser;
				setPatientProfile({
					patientName: getName(metaPatient),
					gender: getGender(metaPatient)?.toLowerCase(),
					age: moment().diff(getBirthdate(metaPatient), 'years', false),
					phone: metaPatient?.phone,
				});
				setSearchPhone(metaPatient?.phone);
				setDoctorDocument(res.meta);
			}

			setIsLoading(false);
		})();
	}, [props.doctorDocumentId]);

	function onNext() {
		if (
			!patientProfile?.patientName ||
			!patientProfile?.gender ||
			!patientProfile?.age ||
			!searchPhone
		) {
			message.warning('Warning: Patient data is not yet complete!');
			return false;
		}

		if (
			props.type === EMetaType.OTHERS &&
			(!doctorDocument?.title || !doctorDocument?.content)
		) {
			message.warning('Warning: Doctor document data is not yet complete!');
			return false;
		}

		if (doctorDocument?.price === undefined) {
			message.warning('Warning: Price is empty!');
			return false;
		}
		saveDoctorDocument();
		return;
	}

	async function apiFindUserByPhone(phone: string) {
		const res = await httpRequest.post<ApiResponseDefault<any>>(
			'/user/find-by-phone',
			{ phone },
		);

		if (res && res.data && res.data.payload && !res.data.payload.doctor) {
			return res.data.payload;
		} else {
			return Promise.reject({
				code: '',
			});
		}
	}

	useEffect(() => {
		if (
			!searchPhone ||
			(searchPhone && searchPhone?.length < 9) ||
			props.doctorDocumentId
		) {
			return;
		}

		const timeout = setTimeout(() => {
			apiFindUserByPhone('+63' + searchPhone)
				.then((res) => {
					const foundData = {
						patientId: res.userId,
						patientName: getName(res),
						age: calculateAge(res.birthdate),
						gender: res.gender,
					};

					if (res.patient?.relatives && res.patient.relatives.length > 0) {
						const relativesData: any[] = [];
						res.patient.relatives.map((item: any) =>
							relativesData.push({
								patientId: res.userId,
								patientName: getName(item),
								age: calculateAge(item.birthdate),
								gender: item.gender,
							}),
						);
						setFoundRelatives(relativesData);
						setFoundPatient(foundData);

						setPatientProfile({
							...patientProfile,
							patientId: res.userId,
						});

						setModalInfoPatientVisible(true);
					} else {
						setPatientProfile({
							...patientProfile,
							...foundData,
							patientId: res.userId,
						});

						setIsPhoneDisable(true);
					}
				})
				.catch((err) => {
					console.info('error', err);
					setPatientProfile({
						...patientProfile,
						patientId: undefined,
					});
				});
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [searchPhone]);

	console.log(doctorDocument);

	async function saveDoctorDocument() {
		// setIsLoading(true);

		const patientName = patientProfile?.patientName
			? patientProfile?.patientName.split(' ')
			: [];
		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (patientProfile?.age ?? 0),
		);

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			transactionConsultationId: doctorDocument?.transactionConsultationId,
			doctorId: user?.userId,
			type: props.type,
			patientId: patientProfile?.patientId,
			metaPatient: {
				firstName: patientName[0],
				middleName:
					patientName.length > 2 ? patientName.slice(1, -1).join(' ') : '',
				lastName:
					patientName.length > 1 ? patientName[patientName.length - 1] : '',
				birthdate,
				gender: patientProfile?.gender?.toLowerCase(),
				phone: searchPhone,
			},
			validUntil: doctorDocument?.validUntil ?? new Date().toISOString(),
			price: doctorDocument?.price ?? 0,
			meta: doctorDocument?.meta,
			isTemporary: true,
		};

		let doctorDocumentId = props.doctorDocumentId;

		if (doctorDocumentId) {
			data = {
				...data,
				meta: doctorDocument as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
			};

			const res = await apiUpdateDoctorDocument(doctorDocumentId, data);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				setDoctorDocument(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: doctorDocument?.transactionConsultationId,
				doctorId: user?.userId,
				type: props.type,
				meta: doctorDocument as
					| MetaMedicalCertificate
					| MetaClearence
					| MetaFitToWork
					| MetaOthers,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				setDoctorDocument(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.setDoctorDocumentId(doctorDocumentId);
			props.setNextStep(props.step + 1);
		} else {
			setIsLoading(false);
		}
	}

	return (
		<form>
			<div className="py-6 px-3">
				{forms.map((formsData, index) => (
					<div key={index}>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">
									{formsData.formTitle}
								</p>
							</div>
							<div className="flex-1">
								{formsData.forms.map((form) => {
									if (!!form?.showWhenType && props.type) {
										if (!form?.showWhenType.includes(props.type)) {
											return <></>;
										}
									}

									return (
										<FormInput
											key={form.label}
											label={form.label}
											maxValue={form.maxValue}
											type={form.type}
											items={form?.items}
											required={form.isRequired}
											withoutHorizontalPadding={!!form.items}
											withoutVerticalPadding={!!form.items}
											value={
												formsData.type === 'patientProfile'
													? form.name === 'address'
														? doctorDocument?.[form.name]
														: patientProfile?.[form.name]
													: doctorDocument?.[form.name]
											}
											suffix={form.suffix}
											prefix={form?.prefix}
											formType={form.formType}
											min={form.min}
											rows={form.rows}
											onChange={(val) => {
												formsData.type === 'patientProfile'
													? form.name === 'address'
														? setDoctorDocument((old) => ({
																...old,
																[form.name]: val,
														  }))
														: setPatientProfile((old) => ({
																...old,
																[form.name]: val,
														  }))
													: setDoctorDocument((old) => ({
															...old,
															[form.name]: val,
													  }));
												form.name === 'phone' && setSearchPhone(val);
											}}
											disabledDate={(d: any) => !d || d.isBefore(new Date())}
											buttonRightLabel={
												form.name === 'phone' &&
												isPhoneDisable && (
													<p
														onClick={() => setShowModalChangePhone(true)}
														className="m-0 text-4 cursor-pointer text-primary"
													>
														Change Phone
													</p>
												)
											}
											disabled={form.name === 'phone' && isPhoneDisable}
										/>
									);
								})}
							</div>
						</div>
						{index < forms.length - 1 && (
							<div className="my-1">
								<Separator />
							</div>
						)}
					</div>
				))}
				<div className="flex gap-x-3">
					<div className="w-1/4" />
					<div className="flex-1">
						<p className="m-0 text-4">
							Document Price <span style={{ color: COLORS.red }}>*</span>
						</p>
						<div className="flex gap-x-2">
							<IconButton
								onClick={() => {
									setIsFree(false);
									setDoctorDocument((old) => ({ ...old, price: 0 }) as any);
								}}
							>
								{!isFree ? (
									<CheckCircle
										className='mt-5'
										width={20}
										height={20}
										color={theme.colors.primary}
									/>
								) : (
									<Circle className='mt-5' width={20} height={20} color={theme.colors.ash800} />
								)}
							</IconButton>
							<div className="flex-1">
								<FormInput
									prefix="₱"
									value={doctorDocument?.price}
									maxValue={1000000000}
									onChange={(val) => {
										if (val) {
											setIsFree(false);
										} else {
											setIsFree(true);
										}
										if (val.length <= 10){
											setDoctorDocument((old) => {
												const oldPrice = old?.price;
												let price = 0;
												if (val.at(-1) === '.') {
													if (!oldPrice?.toString().includes('.')) {
														price = val as any;
													}
												}
												if (!price) {
													price = Number(val);
													if (!isNumber(price) || isNaN(price)) {
														price = oldPrice;
													}
												}
	
												return { ...old, price } as any;
											});
										}
									}}
								/>
							</div>
						</div>
						<div
							onClick={() => {
								setIsFree(true);

								setDoctorDocument((old) => ({ ...old, price: 0 }) as any);
							}}
							style={{ cursor: 'pointer' }}
							className="flex items-center gap-x-2"
						>
							<IconButton
								onClick={() => {
									setDoctorDocument((old) => ({ ...old, price: 0 }) as any);
								}}
							>
								{isFree ? (
									<CheckCircle
										width={20}
										height={20}
										color={theme.colors.primary}
									/>
								) : (
									<Circle width={20} height={20} color={theme.colors.ash800} />
								)}
							</IconButton>
							<p className="m-0 text-4">Free / No need to pay</p>
						</div>
					</div>
				</div>
				<div className="h-6" />
			</div>
			<Separator />
			<div className="py-3 px-4">
				<div className="w-44 ml-auto">
					<Button
						isDisable={
							!patientProfile?.patientName ||
							!patientProfile?.age ||
							!patientProfile?.gender ||
							!doctorDocument?.validUntil ||
							Number(doctorDocument?.price) > 1000000000 ||
							Number(doctorDocument?.medicalAttentionInDays) > 1000 ||
							(!isFree && doctorDocument?.price === 0)
						}
						type="SOLID"
						onClick={onNext}
						isLoading={isLoading}
					>
						Next
					</Button>
				</div>
			</div>
			<ModalInfoPatient
				isVisible={isModalInfoPatientVisible}
				setVisibility={setModalInfoPatientVisible}
				dataPatient={foundPatient}
				dataRelatives={foundRelatives}
				actionHandler={(data) => {
					setPatientProfile({ ...patientProfile, ...data });
					setIsPhoneDisable(true);
					setModalInfoPatientVisible(false);
				}}
			/>
			<ModalConfirmationChangePhone
				isVisible={showModalChangePhone}
				setVisibility={setShowModalChangePhone}
				actionHandler={() => {
					setPatientProfile({
						phone: undefined,
						patientId: undefined,
						patientName: undefined,
						age: undefined,
						gender: undefined,
					});
					setSearchPhone(undefined);
					setIsPhoneDisable?.(false);
					setShowModalChangePhone(false);
				}}
			/>
		</form>
	);
}
