import moment from 'moment';
import { useEffect, useState } from 'react';
import { Plus, Trash2, Edit } from 'react-feather';
import {
	apiCreateRxPrescription,
	apiGetRxPrescription,
	apiUpdateRxPrescription,
} from '../../../../api/rxPrescription';
import COLORS from '../../../../assets/globalColors';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';
import { ReactComponent as Medicines } from '../../../../assets/icons/medicines.svg';
import { theme } from '../../../../assets/theme';
import { useAuth } from '../../../../context/auth.context';
import { RxPrescriptionProperties } from '../../../../types/rxPrescription.type';
import { Button } from '../../../Button/Button/Button';
import IconButton from '../../../Button/IconButton';
import EmptyList from '../../../EmptyList';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import ModalAddMedicine from './Components/Modal/ModalAddMedicine';
import ModalEditMedicine from './Components/Modal/ModalEditMedicine';
import {
	DrugMedicineProperties,
	EditDrugMedicineProperties,
} from '../../../../types/drugMedicine';
import ModalConfirmationChangePhone from './Components/Modal/ModalConfirmationChangePhone';
import { validatePhoneNumber } from '../../../../helpers/validation';

const forms = [
	{
		label: 'Phone Number',
		name: 'phone',
		prefix: (
			<p
				className="m-0 text-black whitespace-nowrap"
				style={{ borderRight: '1px solid gray', paddingRight: 10 }}
			>
				+63
			</p>
		),
		type: EFormType.SINGLE_LINE,
		isRequired: true,
		rows: undefined,
	},
	{
		label: 'Patient Name',
		name: 'patientName',
		type: EFormType.SINGLE_LINE,
		isRequired: true,
		rows: undefined,
	},
	{
		label: 'Gender',
		name: 'gender',
		type: EFormType.ITEM_PICKER_H,
		items: ['MALE', 'FEMALE'],
		isRequired: true,
	},
	{
		label: 'Age',
		name: 'age',
		suffix: <p className="m-0 text-black-60 whitespace-nowrap">Years old</p>,
		formType: 'number',
		min: 0,
		type: EFormType.SINGLE_LINE,
		isRequired: true,
	},
	{
		label: 'Valid Until',
		name: 'validUntil',
		type: EFormType.DATE_PICKER,
		isRequired: true,
	},
];

interface Props {
	step: number;
	prescription?: RxPrescriptionProperties;
	prescriptionHistory?: RxPrescriptionProperties;
	setPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	setPrescriptionHistory: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	setNextStep: () => void;
	setIsEditing: () => void;
	setIsEditingPhone: () => void;

	searchPhone?: string | undefined;
	setSearchPhone: React.Dispatch<React.SetStateAction<any>>;
	patientProfile: any;
	setPatientProfile: React.Dispatch<React.SetStateAction<any>>;
	isPhoneDisable?: boolean;
	setIsPhoneDisable?: React.Dispatch<React.SetStateAction<any>>;
}

export default function FormCreateRx(props: Props) {
	const { user } = useAuth();

	const [showModalAddMedicine, setShowModalAddMedicine] = useState(false);
	const [showModalEditMedicine, setShowModalEditMedicine] = useState(false);
	const [showModalChangePhone, setShowModalChangePhone] = useState(false);
	const [selectedMedicine, setSelectedMedicine] =
		useState<EditDrugMedicineProperties>();
	const [isLoading, setIsLoading] = useState(false);
	const [isHistory, setIsHistory] = useState(false);
	const [isExpired, setIsExpired] = useState(false);

	const todayAfter3Month = new Date();
	todayAfter3Month.setMonth(new Date().getMonth() + 3);

	useEffect(() => {
		if (!props.prescription) {
			props.setPrescription((old) => ({
				...old,
				validUntil: todayAfter3Month.toString(),
			}));
		}
	}, []);

	useEffect(() => {
		if (!props.prescriptionHistory || !isHistory) {
			props.setPrescriptionHistory({ ...props.prescription });
			setIsHistory(true);
		}
	}, [props.prescription]);

	useEffect(() => {
		if (!window.location.search) return;

		const parse = window.location.search.replace('?', '').split('&');

		if (parse.length === 0) return;

		for (const q of parse) {
			const query = q.split('=');
			if (query[0] === 'docId' && !!query[1]) {
				(async () => {
					const res = await apiGetRxPrescription(query[1]);
					if (res) {
						props.setPrescription(res);

						const metaPatient = res?.metaPatient;

						const name = metaPatient?.name;
						const firstName = metaPatient?.firstName;
						const middleName = metaPatient?.middleName;
						const lastName = metaPatient?.lastName;

						const patientName =
							name ??
							`${firstName ? firstName : ''}${
								middleName ? ` ${middleName}` : ''
							}${lastName ? ` ${lastName}` : ''}`;

						const age = metaPatient?.birthdate
							? moment().diff(metaPatient.birthdate, 'years', false)
							: undefined;
						const phone = metaPatient?.phone;
						props.setSearchPhone(phone);

						props.setPatientProfile({
							patientName,
							age,
							gender: metaPatient?.gender,
							phone,
						});
					}
				})();
				props.setIsEditing();
			}
		}
	}, [window.location.search]);

	useEffect(() => {
		if (props.patientProfile && !props.prescription?.metaPatient) return;

		const metaPatient = props.prescription?.metaPatient;

		const name = metaPatient?.name;
		const firstName = metaPatient?.firstName;
		const middleName = metaPatient?.middleName;
		const lastName = metaPatient?.lastName;

		const patientName =
			name ??
			`${firstName ? firstName : ''}${middleName ? ` ${middleName}` : ''}${
				lastName ? ` ${lastName}` : ''
			}`;

		const age = metaPatient?.birthdate
			? moment().diff(metaPatient.birthdate, 'years', false)
			: undefined;
		const phone = props.searchPhone;

		props.setPatientProfile({
			patientName,
			age,
			gender: metaPatient?.gender,
			phone,
		});

		if (!props.prescription?.rxPrescriptionId) {
			props.setPrescription((old) => ({
				...old,
				validUntil: todayAfter3Month.toString(),
			}));
		}
	}, [props.prescription?.metaPatient]);

	useEffect(() => {
		const currentDate = new Date();
		const validUntilDate = props.prescription?.validUntil
			? new Date(props.prescription.validUntil)
			: null;

		if (validUntilDate && validUntilDate < currentDate) {
			setIsExpired(true);
		} else {
			setIsExpired(false);
		}
	}, [props.prescription?.validUntil]);

	function deleteItem(index: number) {
		const newPrescription = [...props.prescription!.prescriptionItems!];
		newPrescription.splice(index, 1);
		props.setPrescription((old) => ({
			...old,
			prescriptionItems: newPrescription,
		}));
	}

	function editItem(drugMedicineProps: EditDrugMedicineProperties) {
		setSelectedMedicine(drugMedicineProps);
	}

	useEffect(() => {
		if (selectedMedicine) {
			setShowModalEditMedicine(true);
		}
	}, [selectedMedicine]);

	useEffect(() => {
		if (!showModalEditMedicine) {
			setSelectedMedicine(undefined);
		}
	}, [showModalEditMedicine]);

	async function savePrescription() {
		setIsLoading(true);
		let isSuccess = false;

		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (props.patientProfile?.age || 0),
		);
		const patientName = (props.patientProfile?.patientName as string)?.split(
			' ',
		);
		let data: RxPrescriptionProperties = {
			...props.prescription,
			prescriptionItems: props.prescription?.prescriptionItems?.map((item) => ({
				...item,
				drugMedicineId: item?.drugMedicineDetail?.drugMedicineId || '',
				drugMedicineVariantId: item?.drugMedicineVariantId || '',
			})),
			isTemporary: true,
			doctorId: user?.userId,
			patientId: props.patientProfile.patientId,
			metaPatient: {
				name: props.patientProfile?.patientName,
				firstName: patientName?.[0],
				middleName: patientName?.slice(1, patientName.length - 1)?.join(' '),
				lastName: patientName.length > 1 ? patientName?.at(-1) : undefined,
				birthdate: birthdate.toISOString(),
				gender: props.patientProfile?.gender,
				phone: props.searchPhone,
			},
		};

		if (props.prescription?.rxPrescriptionId) {
			const res = await apiUpdateRxPrescription(
				props.prescription?.rxPrescriptionId,
				{
					...data,
				},
			);
			if (res) {
				props.setPrescription(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
			};
			const res = await apiCreateRxPrescription(data);
			if (res) {
				props.setPrescription(res);
				isSuccess = true;
			}
		}
		if (isSuccess) {
			props.setNextStep();
		} else {
			setIsLoading(false);
		}
	}

	return (
		<>
			<form>
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Patient Information</p>
							</div>
							<div className="flex-1">
								{forms.map((form) => (
									<FormInput
										key={form.label}
										label={form.label}
										name={form.name}
										type={form.type}
										items={form?.items}
										required={form.isRequired}
										withoutHorizontalPadding={!!form.items}
										withoutVerticalPadding={!!form.items}
										value={
											form.name === 'validUntil'
												? props.prescription?.validUntil
												: props.patientProfile?.[form.name]
										}
										suffix={form.suffix}
										prefix={form.prefix}
										formType={form.formType}
										min={form.min}
										rows={form.rows}
										onChange={(val) => {
											form.name === 'validUntil'
												? props.setPrescription((old) => ({
														...old,
														validUntil: val,
												  }))
												: props.setPatientProfile((old: any) => ({
														...old,
														[form.name]: val,
												  }));

											if (form.name === 'phone') {
												if (val.trim() === '') {
													return;
												  }
												  props.setSearchPhone(val);
												  props.setIsEditingPhone();
											}
										}}
										disabledDate={(d: any) => !d || d.isBefore(new Date())}
										buttonRightLabel={
											form.name === 'phone' &&
											props.isPhoneDisable && (
												<p
													onClick={() => setShowModalChangePhone(true)}
													className="m-0 text-4 cursor-pointer text-primary"
												>
													Change Phone
												</p>
											)
										}
										disabled={form.name === 'phone' && props.isPhoneDisable}
									/>
								))}
								{isExpired && (
									<p style={{ color: 'red', marginTop: '7px' }}>
										Your Rx Prescription has expired
									</p>
								)}
							</div>
						</div>
						<div className="py-4">
							<Separator />
						</div>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Medicines</p>
							</div>
							<div className="flex-1">
								<div className="flex justify-between">
									<p className="m-0 text-4">
										Medicines <span style={{ color: COLORS.red }}>*</span>
									</p>
									<div>
										<button
											onClick={() => setShowModalAddMedicine(true)}
											type="button"
											className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
										>
											<Plus width={24} height={24} />
											Add Medicine
										</button>
									</div>
								</div>
								<div className="mt-2">
									{!!props.prescription?.prescriptionItems &&
									props.prescription?.prescriptionItems.length > 0 ? (
										props.prescription.prescriptionItems.map((item, index) => (
											<div key={item.drugMedicineDetail?.drugMedicineId}>
												<div className="py-3">
													<PrescriptionItem
														name={item.drugMedicineDetail.drugMedicineName}
														dosage={item.drugMedicineDetail.dose}
														qty={item.quantity}
														sig={item.sig}
														onEditItem={() =>
															editItem({
																...item.drugMedicineDetail,
																sig: item.sig,
																quantity: item.quantity,
															})
														}
														onDeleteItem={() => deleteItem(index)}
														doesHavePoint={
															!!item.drugMedicineDetail.points &&
															!!item.drugMedicineDetail.partner
														}
													/>
												</div>
												{index <
													props.prescription!.prescriptionItems!.length - 1 && (
													<Separator />
												)}
											</div>
										))
									) : (
										<EmptyList
											icon={<Medicines color={theme.colors.black50} />}
											title="Prescription empty"
											description="You can add perscription from template or add medication manually"
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
				<Separator />
				<div className="py-3 px-4">
					<div className="w-44 ml-auto">
						<Button
							type="SOLID"
							onClick={savePrescription}
							isLoading={isLoading}
							isDisable={
								isExpired ||
								!props.patientProfile?.patientName ||
								!props.patientProfile?.age ||
								!props.patientProfile?.gender ||
								!props.prescription?.prescriptionItems ||
								!props.prescription?.validUntil ||
								props.prescription?.prescriptionItems.length === 0 ||
								Number(props.patientProfile?.age) < 1 ||
								validatePhoneNumber(props.patientProfile?.phone)?.length > 0 ||
								!props.patientProfile?.phone
							}
						>
							Next
						</Button>
					</div>
				</div>
			</form>
			<ModalAddMedicine
				isShow={showModalAddMedicine}
				onCloseModal={() => setShowModalAddMedicine(false)}
				setPrescription={props.setPrescription}
			/>
			<ModalEditMedicine
				medicineProps={selectedMedicine}
				isShow={showModalEditMedicine}
				onCloseModal={() => setShowModalEditMedicine(false)}
				setPrescription={props.setPrescription}
			/>
			<ModalConfirmationChangePhone
				isVisible={showModalChangePhone}
				setVisibility={setShowModalChangePhone}
				actionHandler={() => {
					props.setPatientProfile({
						phone: undefined,
						patientId: undefined,
						patientName: undefined,
						age: undefined,
						gender: undefined,
					});
					props.setSearchPhone(undefined);
					props.setIsPhoneDisable?.(false);
					setShowModalChangePhone(false);
				}}
			/>
		</>
	);
}

function PrescriptionItem(props: {
	name?: string;
	dosage?: string;
	qty: number;
	sig?: string;
	onEditItem: () => void;
	onDeleteItem: () => void;
	doesHavePoint?: boolean;
}) {
	return (
		<div className="flex gap-x-2 items-center">
			<div className="flex-1">
				<div className="flex gap-x-1 items-center">
					<p className="m-0 text-4.5 font-medium">
						{props.name}{' '}
						<span>
							{props.doesHavePoint && <Logo width={20} height={20} />}
						</span>
					</p>
				</div>
				<div className="mt-2 flex gap-x-2.5">
					<p className="flex-1 m-0 text-3.5 text-black-70">{props.dosage}</p>
					<p className="flex-1 m-0 text-3.5 text-black-70">
						{props.qty ? `qty. ${props.qty}` : ''}
					</p>
				</div>
				<div>
					<p className="m-0 mt-2 text-3.5 text-black-70">{props.sig}</p>
				</div>
			</div>
			<IconButton onClick={props.onEditItem}>
				<Edit width={24} height={24} color={theme.colors.primary} />
			</IconButton>
			<IconButton onClick={props.onDeleteItem}>
				<Trash2 width={24} height={24} color={theme.colors.primary} />
			</IconButton>
		</div>
	);
}
