import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiGetDoctorDocument } from '../../../../api/doctorDocument';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
	MetaFitToWork,
	MetaMedicalCertificate,
	MetaOthers,
} from '../../../../types/doctorDocument.type';
import {
	EPaymentStatus,
	PaymentProperties,
} from '../../../../types/payment.type';
import { Button } from '../../../Button/Button/Button';
import Separator from '../../../Separator';
import { DoctorDocumentNav } from '../MainDoctorDocument';

interface Props {
	doctorDocumentId?: string;
	toPreview: () => void;
}

export default function DoctorDocumentDetail(props: Props) {
	const history = useHistory();

	const [doctorDocument, setDoctorDocument] =
		useState<DoctorDocumentProperties>();

	useEffect(() => {
		(async () => {
			if (!props.doctorDocumentId) return;

			const res = await apiGetDoctorDocument(props.doctorDocumentId);
			if (res) {
				setDoctorDocument(res);
			}
		})();
	}, [props.doctorDocumentId]);

	return (
		<div className="flex-1 py-6 px-3 flex flex-col">
			<div className="flex-1">
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0">Document Information</p>
					</div>
					<div className="flex-1">
						<div className="flex gap-x-3">
							<ItemForm
								title="Document Type"
								value={getValueFromEnum(doctorDocument?.type)}
								isTagDocType
							/>
							<ItemForm
								title="Status"
								value={getStatus(
									doctorDocument?.price,
									doctorDocument?.payment,
								)}
								isTagStatus
							/>
						</div>
						<ItemForm
							title="Price"
							value={
								doctorDocument?.price
									? `₱${doctorDocument?.price?.toString()}`
									: '₱0'
							}
						/>
						<div className="flex gap-x-3">
							<ItemForm
								title="Prescribed on"
								value={moment(doctorDocument?.createdAt).format(
									'MMMM DD, YYYY',
								)}
							/>
							<ItemForm
								title="Valid Until"
								value={moment(doctorDocument?.validUntil).format(
									'MMMM DD, YYYY',
								)}
							/>
						</div>
					</div>
				</div>
				<div className="py-5">
					<Separator style={{ height: 2 }} />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0">Patient Information</p>
					</div>
					<div className="flex-1">
						<ItemForm
							title="Patient Name"
							value={getName(doctorDocument?.metaPatient)}
						/>
						<ItemForm
							title="Age"
							value={
								moment()
									.diff(
										getBirthdate(doctorDocument?.metaPatient),
										'years',
										false,
									)
									.toString() + ' yrs. old'
							}
						/>
						<ItemForm
							title="Gender"
							value={getGender(doctorDocument?.metaPatient)}
						/>

						{doctorDocument?.type !== EMetaType.OTHERS && (
							<ItemForm
								title="Phone"
								value={(doctorDocument?.metaPatient as any)?.phone || '-'}
							/>
						)}

						{doctorDocument?.type === EMetaType.MEDICAL_CERTIFICATE && (
							<ItemForm
								title="Address"
								value={(doctorDocument?.meta as any)?.address || '-'}
							/>
						)}
					</div>
				</div>
				<div className="py-5">
					<Separator style={{ height: 2 }} />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-">Detail Document</p>
					</div>
					<div className="flex-1">
						{doctorDocument?.type === EMetaType.MEDICAL_CERTIFICATE ? (
							<>
								<ItemForm
									title="Clinic / Hospital"
									value={
										(doctorDocument?.meta as MetaMedicalCertificate)?.clinicName
									}
								/>
								<ItemForm
									title="Diagnosis"
									value={
										(doctorDocument?.meta as MetaMedicalCertificate)?.diagnosis
									}
								/>
								<ItemForm
									title="Medical attention / rest for"
									value={
										(doctorDocument?.meta as MetaMedicalCertificate)
											?.medicalAttentionInDays
											? (
													doctorDocument?.meta as MetaMedicalCertificate
											  )?.medicalAttentionInDays?.toString() + ' days'
											: ''
									}
								/>
							</>
						) : doctorDocument?.type === EMetaType.CLEARANCE ? (
							<>
								<ItemForm
									title="Clearance title"
									value={(doctorDocument?.meta as MetaClearence)?.title}
								/>
								<ItemForm
									title="Comorbid Conditions"
									value={
										(doctorDocument?.meta as MetaClearence)?.comorbidConditions
									}
								/>
								<ItemForm
									title="Procedure"
									value={(doctorDocument?.meta as MetaClearence)?.procedures}
								/>
								<ItemForm
									title="Symptoms"
									value={(doctorDocument?.meta as MetaClearence)?.symptoms}
								/>
								<ItemForm
									title="Functional Capacity"
									value={
										(doctorDocument?.meta as MetaClearence)?.functionalCapacity
									}
								/>

								<ItemForm
									title="Chest Xray"
									value={(doctorDocument?.meta as MetaClearence)?.chestXRay}
								/>
								<ItemForm
									title="12-L ECG"
									value={(doctorDocument?.meta as MetaClearence)?.lecg12}
								/>
								<ItemForm
									title="Blood tests"
									value={(doctorDocument?.meta as MetaClearence)?.bloodTest}
								/>
								<ItemForm
									title="Other tests"
									value={(doctorDocument?.meta as MetaClearence)?.otherTest}
								/>
								<ItemForm
									title="Patient Stratified"
									value={
										(doctorDocument?.meta as MetaClearence)?.patientStratified
									}
								/>
								<ItemForm
									title="Recommendations"
									value={
										(doctorDocument?.meta as MetaClearence)?.recommendation
									}
								/>
							</>
						) : doctorDocument?.type === EMetaType.FIT_TO_WORK ? (
							<>
								<ItemForm
									title="Comorbid Conditions"
									value={
										(doctorDocument?.meta as MetaFitToWork)?.comorbidConditions
									}
								/>
								<ItemForm
									title="Chief Complaint"
									value={
										(doctorDocument?.meta as MetaFitToWork)?.chiefComplaint
									}
								/>
								<ItemForm
									title="PE Findings"
									value={(doctorDocument?.meta as MetaFitToWork)?.peFindings}
								/>
								<ItemForm
									title="Test Results"
									value={(doctorDocument?.meta as MetaFitToWork)?.testResult}
								/>
								<ItemForm
									title="Physical Condition"
									value={
										(doctorDocument?.meta as MetaFitToWork)?.physicalCondition
									}
								/>
								<ItemForm
									title="Recommendation"
									value={
										(doctorDocument?.meta as MetaFitToWork)?.recommendation
									}
								/>
							</>
						) : doctorDocument?.type === EMetaType.OTHERS ? (
							<>
								<ItemForm
									title="Document Title"
									value={(doctorDocument?.meta as MetaOthers)?.title}
								/>
								<ItemForm
									title="Custom Content"
									value={(doctorDocument?.meta as MetaOthers)?.content}
								/>
							</>
						) : undefined}
					</div>
				</div>
			</div>
			<div className="pt-4">
				<Separator />
			</div>
			<div className="pt-4 px-4">
				<div className="max-w-sm ml-auto flex gap-x-3">
					<Button
						type="TRANSPARENT"
						onClick={() =>
							history.push(
								`/app/doctor-documents/${getDocTypeUrlParam(
									doctorDocument?.type,
								)}/${props.doctorDocumentId}`,
							)
						}
					>
						Edit
					</Button>
					<Button type="SOLID" onClick={props.toPreview}>
						Preview
					</Button>
				</div>
			</div>
		</div>
	);
}

interface ItemProps {
	title: string;
	value?: string;
	isTagDocType?: boolean;
	isTagStatus?: boolean;
}

function ItemForm(props: ItemProps) {
	let color: {
		border?: string;
		bg: string;
		text: string;
	} = {
		border: '',
		bg: '',
		text: '',
	};

	if (props.isTagDocType) {
		switch (props.value) {
			case 'Medical Certificate':
				color = {
					bg: 'bg-blue-10',
					text: 'text-blue',
				};
				break;
			case 'Fit To Work':
				color = {
					bg: 'bg-purple-10',
					text: 'text-purple-100',
				};
				break;
			case 'Clearance':
				color = {
					bg: 'bg-green-10',
					text: 'text-green-100',
				};
				break;
			default:
				color = {
					bg: 'bg-white',
					text: 'text-black',
				};
		}
	} else if (props.isTagStatus) {
		switch (props.value) {
			case 'Free':
				color = {
					border: 'border-gray-80',
					bg: 'bg-gray-10',
					text: 'text-gray-100',
				};
				break;
			case 'Pending':
				color = {
					border: 'border-yellow',
					bg: 'bg-yellow-10',
					text: 'text-yellow-100',
				};
				break;
			case 'Cleared':
				color = {
					border: 'border-green',
					bg: 'bg-green-10',
					text: 'text-green-100',
				};
				break;
			default:
				color = {
					border: 'border-black',
					bg: 'bg-white',
					text: 'text-black',
				};
		}
	}

	const value = props.value ? props.value : 'None';

	return (
		<div className="mb-1">
			<p className="m-0 text-3.5 text-gray-60">{props.title}</p>
			{props.isTagDocType ? (
				<div
					className={`rounded-full ${color.bg} ${color.text} px-4 py-1 text-3.5`}
				>
					<p className="m-0">{value}</p>
				</div>
			) : props.isTagStatus ? (
				<div
					className={`rounded-1.5 border border-solid ${color?.border} ${color.bg} ${color.text} px-4 py-1 text-3.5`}
				>
					<p className="m-0">{value}</p>
				</div>
			) : (
				<p className={'m-0 text-4.5' + (!props.value ? ' text-gray-70' : '')}>
					{value}
				</p>
			)}
		</div>
	);
}

function getValueFromEnum(enumVal?: any) {
	return enumVal
		?.toLowerCase()
		.split('_')
		.map((t: string) => t[0].toUpperCase() + t.slice(1))
		.join(' ');
}

function getStatus(price?: number, payment?: PaymentProperties) {
	if (!price && !payment?.totalNeedToPay) {
		return 'Free';
	} else {
		switch (payment?.paymentStatus) {
			case EPaymentStatus.PAID_FULL:
				return 'Cleared';
			case EPaymentStatus.CANCELLED_BY_SYSTEM:
			case EPaymentStatus.CANCELLED_BY_USER:
				return 'Canceled';
			default:
				return 'Pending';
		}
	}
}

function getDocTypeUrlParam(type?: EMetaType) {
	let param = getValueFromEnum(type).toLowerCase().replaceAll(' ', '-');
	if (
		param ===
		getValueFromEnum(EMetaType.OTHERS).toLowerCase().replaceAll(' ', '-')
	) {
		param = 'own-template';
	}
	return param;
}
